import { Button } from '@design-system';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { csrClient } from '../../../apollo/api/avantArteClient';
import { useAuth, useFlow } from '../../../providers';
import {
  GeneratedCreateSubscriptionsInput,
  IsSubscribedDocument,
} from '../../../types/generated';
import { TrackEmailSubscription } from '../../../utils/analytics';
import { useAnalyticsEmitter } from '../../../utils/analytics/emitter';
import { captureError } from '../../../utils/capture';
import { useGsiSetup } from '../hooks/useGsiSetup';
import { useSubscriptionsManager } from '../hooks/useSubscriptionsManager';
import { AuthAnalytics, AuthFlow } from '../types';
import { PhoneContainer } from './PhoneContainer';
import { ProfileContainer } from './ProfileContainer';
import { SuccessContainer } from './SuccessContainer';
import { ProfileTitleContainer } from './TitleContainers';

interface Props {
  hideSuccessScreen?: boolean;
  optInGeneral: boolean;
  showPhoneStep?: boolean;
  subscriptions: GeneratedCreateSubscriptionsInput[];
  hardRefresh: boolean;
}

export const GoogleAuthContainer: React.FC<Props> = ({
  hardRefresh,
  hideSuccessScreen,
  optInGeneral,
  showPhoneStep,
  subscriptions,
}) => {
  const { t } = useTranslation('authentication');
  const [emitter] = useAnalyticsEmitter();
  const { closeFlow, flowConfig, startFlow } = useFlow();
  const { loginGoogle } = useAuth();
  const { subscribe } = useSubscriptionsManager();
  const { reload } = useRouter();

  const { gsiContainer, handleGsi } = useGsiSetup(async (response) => {
    try {
      const resp = await loginGoogle(response.credential);
      const { account, isNewAccount } = resp.data!.useLoginCode;

      const isPhoneComplete = Boolean(!showPhoneStep || account.phone !== null);

      const profileComplete = Boolean(
        account.fullName && account.pronouns && account.countryCode,
      );

      emitter('google', 'success', { account_already_exists: !isNewAccount });

      if (account) {
        TrackEmailSubscription({
          artistId: flowConfig?.analyticsProperties?.artist_id,
          artistName: flowConfig?.analyticsProperties?.['artist name'],
          emailAddress: account.email || '',
          accountId: account.id,
          releaseName: flowConfig?.analyticsProperties?.subscription_name,
          productId: flowConfig?.analyticsProperties?.product_id,
          productTitle: flowConfig?.analyticsProperties?.product_title,
          trackEvent: false,
          mode: 'GENERAL',
          type: 'success',
        });
        const analyticsProps = { is_signed_in: false };

        emitter('subscribe', 'submit', analyticsProps, {
          omitObject: true,
        });

        const success = await subscribe(subscriptions);
        if (success) {
          csrClient.refetchQueries({ include: [IsSubscribedDocument] });
          emitter('subscribe', 'success', analyticsProps, {
            omitObject: true,
          });
        }
      }

      if (isPhoneComplete && profileComplete) {
        closeFlow();

        if (hardRefresh) {
          reload();
        }

        return;
      }

      const name = flowConfig?.name as AuthFlow;
      const signIn = name === AuthFlow.SignIn;

      startFlow({
        name: AuthFlow.ProfileOnly,
        steps: [
          {
            id: 'profile',
            content: <ProfileContainer optInGeneral={optInGeneral} />,
            title: <ProfileTitleContainer />,
            analytics: {
              name: signIn
                ? AuthAnalytics.SignInProfile
                : AuthAnalytics.SignUpProfile,
            },
          },
          ...(isPhoneComplete === false
            ? [
                {
                  id: 'phone',
                  content: (
                    <PhoneContainer phone={account.phone || undefined} />
                  ),
                  title: t('screens.phone.title'),
                  analytics: {
                    name: signIn
                      ? AuthAnalytics.SignInPhone
                      : AuthAnalytics.SignUpPhone,
                  },
                },
              ]
            : []),
          ...(!signIn && !hideSuccessScreen
            ? [
                {
                  id: 'success',
                  content: <SuccessContainer />,
                  hideBackButton: true,
                  analytics: {
                    name: AuthAnalytics.SignUpConfirmation,
                  },
                },
              ]
            : []),
        ],
      });
    } catch (e) {
      const error = e as unknown as Error;
      captureError(error);
      emitter('google', 'error', { error });
    }
  });

  return (
    <div className="relative">
      <div ref={gsiContainer} style={{ display: 'none' }} />

      <Button
        fullWidth
        label={t('google')}
        onClick={() => {
          handleGsi();
          emitter('continue', 'clicked', { provider: 'google' });
        }}
        size="lg"
        variant="tertiary"
      />
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt="Google sign in"
        className="pointer-events-none absolute left-6 top-1/2 h-5 w-5 -translate-y-1/2 select-none"
        src="/images/google-signin-logo.svg"
      />
    </div>
  );
};
